import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Arabic`}</strong>{` is a Semitic language based on tri-literal roots. It's the official language of 26 states and Modern Standard Arabic is one of six official languages of the United Nations.  `}</p>
    <br />
    <p>{`Arabic is also the language of Quran and we should understand that Quran is an `}<em parentName="p">{`Arabic phenomenon`}</em>{`. The words and sentence constructions Allah chose to deliver the message is immaculate and prestine. `}</p>
    <br />
    <p>{`I think, Learning Basic Arabic Grammar rules should be the first step forward to any Muslim whose native tongue is not Arabic and I would further argue we all should learn Arabic grammar for understanding Quran to cherish its beauty. `}</p>
    <br />
    <p>{`Allah says in Quran,  `}</p>
    <blockquote>
      <p parentName="blockquote">{`ولقد يسرنا القرآن للذكر فهل من مدكر (Ref 54:17, 54:22 ,54:32, 54:40 )`}<br parentName="p"></br>{`
`}{`And We have certainly made the Qur'an easy for remembrance, so is there any who will remember? `}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`If Quran is made easy by Allah, so is the language of Quran. And so is its grammar.`}</strong></p>
    <br />
    <p>{`There are many Arabic grammar courses available online and free of cost. Indo/Pak people may learn Arabic grammar in Urdu but I see a huge resource for people who know English and would like to learn Arabic grammar in English.`}</p>
    <br />
    <p>{`In this post, I would like to summarize basics of Arabic grammar for beginners. We will discuss parts of speech in Arabic grammar, verbs in Arabic grammar, nouns in Arabic grammar in simple English. These basic Arabic grammar rules will serve as the foundation for a beginner.`}</p>
    <br />
    <p>{`So, without further ado, lets begin and make the basics of Arabic grammar super easy ;) (إن شاء الله)`}</p>
    <br />
    <p>{`First things first, `}</p>
    <h2>{`How to begin learning Arabic Grammar?`}</h2>
    <br />
    <p>{`Learning any language begins with learning its alphabets then words and then we make sentence. Similarly, Learning Arabic language can be broadly classified into 3 broad categories,`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Ilm ul Aswat (علم الأصوات)`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Sarf (الصرف)`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Nahw (النحو)`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.355828220858896%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABC0lEQVQY042Q207CQBBA+/9/oTwgJD4K1GpiwUqMaJdWQ2tRIAjUAjViL3uZ3XWqT14SnUw2ZyZzdpIx9NeQSodL6tzGF/7K8Vf9uwTh3I8nCdc/wvhWK6Vna07GG/8xde/X3nhLouQmTBap+Fv+9DEBFGfAKIDESmPvX7KUEo0PUJgIIACbv8hAqaJUM4YvsmBCglJCSFqKLOdZjqDwM6mAgyhKKEvFmCxLHDYy93resx9OrafuGQtGwKrDsNmUe2TeteO+w/0hXy6q/YxRz8uIG5rtN+IWg4GxPTkOGvVhbT9q1POrS1yCc0UwSjutoHkQHTZfLZNOJ9UtON/17E2nRWp7z+2jF8t8Bw+hiM9UmfgfAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3 classifications of Learning Arabic Language",
          "title": "3 classifications of Learning Arabic Language",
          "src": "/static/480d48a4675c6299ce045986b8b0af68/c7dcc/three-parts-of-learning-arabic.png",
          "srcSet": ["/static/480d48a4675c6299ce045986b8b0af68/222b7/three-parts-of-learning-arabic.png 163w", "/static/480d48a4675c6299ce045986b8b0af68/ff46a/three-parts-of-learning-arabic.png 325w", "/static/480d48a4675c6299ce045986b8b0af68/c7dcc/three-parts-of-learning-arabic.png 641w"],
          "sizes": "(max-width: 641px) 100vw, 641px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Ilm ul Aswat (علم الأصوات)`}</h3>
    <p>{`This is the first level of studying Arabic language in which we study letters e.g. ي،د،ح etc. We learn,`}</p>
    <ul>
      <li parentName="ul">{`How to pronounce letters.`}</li>
      <li parentName="ul">{`Understand point of articulation of letters i.e. `}<em parentName="li">{`Makhraj`}</em>{` (مخرج)`}</li>
      <li parentName="ul">{`Understand characteristics of letters i.e. `}<em parentName="li">{`Sifat`}</em>{` (صفات)  `}</li>
    </ul>
    <br />
    <p>{`A part of this ilm comes under study of Tajweed.`}</p>
    <h3>{`Sarf (الصرف)`}</h3>
    <p>{`This is the second level where we study the whole word (كَلِمَة) e.g. زيد, يدخل, ثُمّ. We study the rules related to a word e.g. `}</p>
    <ul>
      <li parentName="ul">{`فعل مجرد `}</li>
      <li parentName="ul">{`فعل مزيد`}</li>
    </ul>
    <br />
    <p>{`And so on ...`}</p>
    <h3>{`Nahw (النحو)`}</h3>
    <p>{`This is the third level in which we study the entire sentence i.e. `}<em parentName="p">{`Jumla`}</em>{` (الجُمل) OR `}<em parentName="p">{`Kalam`}</em>{` (الكلام). One fine point is in النحو, We study كَلِمَة in الجُملة, `}<strong parentName="p">{`Not`}</strong>{` كَلِمَة by itself.`}</p>
    <br />
    <p>{`Before moving forward to `}<em parentName="p">{`parts of speech`}</em>{`. I would love to quickly discuss,  `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Definition of Kalam (الكلام)`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`تعريف الكلام عند النحوين`}</strong></p>
    <p>{`In Simplest definition, Kalam is`}</p>
    <blockquote>
      <p parentName="blockquote">{`اللفظ المفيد`}</p>
    </blockquote>
    <br />
    <p>{`Meaning `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Useful expression`}</code>{`, And by Useful we meant when we finish our talking (i.e. الكلام) the listener is not waiting for anything else.`}<br parentName="p"></br>{`
`}{`Example:`}</p>
    <ul>
      <li parentName="ul">{`جاء زيد إلى البيت (Zaid came to the House) is a full statement (اللفظ المفيد)`}</li>
      <li parentName="ul">{`... إن جاء زيد إلى البيت (If Zaid came home ...) is `}<strong parentName="li">{`Not`}</strong>{` full statement (اللفظ غيرُ المفيد) because the listener is waiting for the rest of the sentence.`}</li>
    </ul>
    <br />
    <p>{`That's enough covering the generalities, let's quickly begin the discussion for `}</p>
    <h2>{`Part of Speech in Arabic Grammar`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`أقسام الكلام`}</strong></p>
    <p>{`There are 3 parts of Speech in Arabic Grammar. Every word that we read/speak will fall into following 3 categories,  `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Ism (اسم)`}</strong>{`  `}</li>
      <li parentName="ol"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Fa'il (فعل)`}</strong>{`  `}</li>
      <li parentName="ol"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Harf (حرف)`}</strong>{`  `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACKElEQVQ4y41Ua3PSQBTt//8JTp3qN8cZ/aBMxRYtg1XbWiDh0U4JfVAq75BMEkyyjxzvZkkmWJxh4Q67e3PPPWfPhj0UR5KobzqEkOBcgFHwdbBsLQQ9l6xLkg2IvU08nfRWHNaEYWADQwd4WCR67uq4nQk8zuOtoFsBbV/AvA/w6VsHpaqJWnOAjydGOldxfjVLQTmp2Alw4XG0HyN8b/1G5czCz+4Ep+YTNeji+KyHuuWSAp4ey06Aq4gkLRhGJG/qA0+2xGDKMAv0ergUmDg7SN5IKoOkpBAIgxC+E4AzRtsy3acHdjclKbjNYg6ugol0XpT5X4ZZshhqcKaBJDGKQ5buqd9EJthWlwKmky3MJDGJI5bPWcT1vACes8tAc4ZFufRRsqI/cV4kiOXKC/O1ahBSPnluADGkjuFtH0HbhGsaCLttONc9umMETSaEvRs4jQbmF7+w6rQQj8dpbTC14RoG1TThtUyIINCSRRyDtQyMvhzj5OU+vB+niK462hDPA2/WYZU+oPb6AE6tisjqaUXOEsOjMir7LzAsH0LaCy1ZAUZGA3eHJbTfv8OocoSIWKok932ElxcYfC6j9uoAi+pXxH0NKJc27qmm/vYN7qghn8/WppDkqG/BJSY+UQ9IdjR40GdHzcKb6zTnGs00F49HOkcSPVK2bFzCo5xqjn9NUaFcLB52UnBSSpkffpbD2t3M8Y1rs/k3ljx7C/KrteUNydZ/Aed8z1U33NNOAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3 Parts of Speech in Arabic Language",
          "title": "3 Parts of Speech in Arabic Language",
          "src": "/static/ee500881dbf820a562e14603784ee401/7c2a6/3-parts-of-speech-arabic.png",
          "srcSet": ["/static/ee500881dbf820a562e14603784ee401/222b7/3-parts-of-speech-arabic.png 163w", "/static/ee500881dbf820a562e14603784ee401/ff46a/3-parts-of-speech-arabic.png 325w", "/static/ee500881dbf820a562e14603784ee401/7c2a6/3-parts-of-speech-arabic.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Ism (اسم)`}</h3>
    <p>{`Ism in Arabic Grammar can be compared to nouns in English language (Caveat: I want to keep it simple ) e.g. زيد ,فاطمة ,كتاب etc. To identify اسم from an Arabic sentence there are few signs, which we will discuss now.  `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Signs of Ism (علاماتُ الاسم)`}</strong><br parentName="p"></br>{`
`}{`There are multiple signs of a word being Ism. But here we will discuss 4 of them which are as follows,  `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`If we find ال on any word then that word is Ism e.g. `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`الحمد`}</strong>{` لله (All praise and gratitute belongs to Allah)`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`دخول ال`}</p>
        </blockquote>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If we find tanween on a word then that word is Ism e.g. جاء `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`زيدٌ`}</strong>{` (Zaid Came)  `}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`التنوين`}</p>
        </blockquote>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If we find Harf Jar (Oops I've used `}<em parentName="p">{`Harf`}</em>{` without defining it, be patient we will learn about it soon إن شاء الله) before any word then that word is Ism e.g. من `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`البيتِ`}</strong>{` (from house). Here, house is Ism as it is followed by Harf Jar (من)  `}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`دخول حرف الجر على الكلمة  `}</p>
        </blockquote>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If a word is مجرور (Majroor) then it is Ism. A word can be مجرور without even having Harf Jar before it for e.g. كتابُ `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`اللهِ`}</strong>{` (Book of Allah) where كتابُ is `}<em parentName="p">{`Mudhaf ilaihi`}</em>{` (مضاف اليه) so is مجرور. OR من البيتِ `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`البعيدِ`}</strong>{` (From far house) where البعيدِ is `}<em parentName="p">{`Sifa`}</em>{` (صفة) of البيتِ and is مجرور.  `}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`الجر`}</p>
        </blockquote>
      </li>
    </ol>
    <h3>{`Fa'il (فعل)`}</h3>
    <p>{`Fa'il in Arabic grammar can be compared to verbs in English language e.g. كتب ,يذهب ,اخرج`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Signs of Fa'il (علامات الفعل)`}</strong><br parentName="p"></br>{`
`}{`There are multiple signs of a word being Fa'il. But here we will discuss 4 of them which are as follows  `}</p>
    <ol>
      <li parentName="ol">{`If we find قد before a word then it is الفعل e.g. قد `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ذهب`}</strong>{`, قد `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يذهب`}</strong>{`.`}<br parentName="li"></br>
        <strong parentName="li">{`Note`}</strong>{`: This sign works with both `}<em parentName="li">{`Fa'il Madhi`}</em>{` (فعل ماض) and `}<em parentName="li">{`Fa'il Mudhari`}</em>{` (فعل مضارع). (Details of فعل ماض and فعل مضارع can be found in `}<a parentName="li" {...{
          "href": "/verbs-in-arabic-grammar/"
        }}>{`Verbs in Arabic Grammar`}</a>{` article)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`دخول قد`}</p>
        </blockquote>
      </li>
      <li parentName="ol">{`If we find `}<em parentName="li">{`Seen`}</em>{` (السين) Or `}<em parentName="li">{`Sawfa`}</em>{` (سوف) before a word then it is الفعل e.g. `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`س`}</strong>{`أذهب Or `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`سوف`}</strong>{` أذهب.`}<br parentName="li"></br>
        <strong parentName="li">{`Note`}</strong>{`: This sign works with فعل مضارع only.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`دخول السين أو سوف`}</p>
        </blockquote>
      </li>
      <li parentName="ol">{`If we find لم before a word then it is الفعل e.g. لم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أذهب`}</strong>{` إلى البيت.`}<br parentName="li"></br>
        <strong parentName="li">{`Note`}</strong>{`: This sign works with فعل مضارع only.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`(دخول ( لم`}</p>
        </blockquote>
      </li>
      <li parentName="ol">{`If we find تاء المتكلم at the end of a word then it is الفعل e.g. ذهب`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ت`}</strong>{` OR خرج`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ت`}</strong>{`.`}<br parentName="li"></br>
        <strong parentName="li">{`Note`}</strong>{`: This sign works with فعل ماض`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`دخول تاء المتكلم`}</p>
        </blockquote>
      </li>
    </ol>
    <br />
    <p>{`Above signs identify فعل ماض and فعل مضارع, but we have `}<em parentName="p">{`Fa'il Amr`}</em>{` (فعل الأمر) also in Arabic language. فعل الأمر must have following two characteristics,  `}</p>
    <ol>
      <li parentName="ol">{`It has to be a command`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`أن يدل على الأمر`}</p>
        </blockquote>
      </li>
      <li parentName="ol">{`It has to be derived from فعل e.g. قُل is derived from قال OR it should accept ياء المخاطبة e.g. اذهبي from verb اذهب`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`أن يكون مشتقاً من فعل`}<br parentName="p"></br>{`
`}{`أو`}<br parentName="p"></br>{`
`}{`أن يقبل ياء المخاطبة`}</p>
        </blockquote>
      </li>
    </ol>
    <br />
    <p><strong parentName="p">{`NOTE:`}</strong>{` We have added second condition because there are certain الأمر in Arabic language which are not derived from فعل Or which doesn't accept ياء المخاطبة.  `}</p>
    <p>{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`حيَّ`}</strong>{` على الصلاة (Come to prayer) here حيَّ is not فعل الأمر but it is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`اسم فعل`}</code>{`.`}</p>
    <h3>{`Harf (حرف)`}</h3>
    <p>{`Harf in Arabic grammar can be compared to prepositions in English language e.g. ... من   إلى  عن  على.  `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Signs of Harf (علامة الحرف)`}</strong><br parentName="p"></br>{`
`}{`There are `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`No Signs`}</strong>{` for Harf`}</p>
    <blockquote>
      <p parentName="blockquote">{`ليس له علامة`}</p>
    </blockquote>
    <br />
    <p>{`People might say, "Why there is no sign for Harf."`}<br parentName="p"></br>{`
`}<em parentName="p">{`My Response`}</em>{`: See, At times no Sign is a sign in itself. (I copied my teacher ;)`}</p>
    <p>{`Lemme explain,`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "bg-yellow heading1"
      }}>{`ج`}</strong>{`: This letter has a sign in middle, implying it is Geem.`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "bg-yellow heading1"
      }}>{`خ`}</strong>{`: This letter has a sign on top, implying it is Kha.`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "bg-yellow heading1"
      }}>{`ح`}</strong>{`: This letter has `}<strong parentName="p">{`NO Sign`}</strong>{`, implying it is Haa.  `}</p>
    <br />
    <p>{`See How amazing ;)  `}</p>
    <br />
    <p>{`Same thing is with Harf. If we do't see علاماتُ الاسم and علامات الفعل on a word, then this is the sign that `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`it is actually Harf`}</strong></p>
    <br />
    <p>{`حرف is further divided into 2 categories  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.21472392638037%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVElEQVQoz42R207CQBCG+/5PYTBGA5JwDhRCjAnQw1JFCBRUYqkUuFACZWn3WAclBCuJTOZid2a++Wd2lei3CSH7btB1SXcSPjlBzw07Dpl9UkjJKG5K7M64HEzJ+CPS+4tM1RzO6Gghp0t2iv0DcyGHHhnNue2RnoOfF3zgsfkOjv6HwQgTOOT+hqx9st4QOEPHc+Hd5jD9NyC44JRF8jxYSklBmovjJzxEYk32sDyQlMtTOowJ8FhCkYwBARyMSlZ4J/QTOR6HMwjCT4QrLDiPYCVKwZWNofl6a1wuvZYKdjYzqVZ8rRm0EQ+CA4mRsTW0N7U8zGVfigWowYa2RYaCWw2MTP36CiVvGpeJ+8TFSmuShzbz/T1M6dbQQwt10rfdTPoxnbJSSWzqUKNgvQUJO58dFfOOWrHzuaBtBtaRMmMgQiz0Xq9Naqp3V3drKpAQ/AKZEWxwQOQUfQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2 Types of Harf",
          "title": "2 Types of Harf",
          "src": "/static/073009af0867c28fe59df53b92897eec/9144d/2-harf.png",
          "srcSet": ["/static/073009af0867c28fe59df53b92897eec/222b7/2-harf.png 163w", "/static/073009af0867c28fe59df53b92897eec/ff46a/2-harf.png 325w", "/static/073009af0867c28fe59df53b92897eec/9144d/2-harf.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`حرف مبنى`}</strong>{` (Harf Mabna)`}</p>
    <ul>
      <li parentName="ul">{`These are simply Arabic alphabets e.g. ... أ – ب – ت – ث –ج –ح, we call them `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حروف الهجاء`}</code></li>
      <li parentName="ul">{`They are called `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مبنى`}</code>{` because we make words from these حرف  `}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`حرف معنى`}</strong>{` (Harf Ma'na)`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`معنى`}</code>{` implies these حرف have meanings e.g. ... من (from) ,إلى (towards) , ك (similar to) ,لِ (for)`}</li>
    </ul>
    <br />
    <p>{`There is another category in which Harf is divided into two,  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.21472392638037%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVQoz42RW0/CMBTH9/0/g74JZG9GLjqGIyRGYRfuARmEuC0M9uAFttm1Pe3siBKZJHLSh3Pa/v7/nlMpPQ7G+MRDIw+P3GTooLGXDBy8fiPiiKf5kHI1BT5d4eVrqk+Ca9WYrYkd8NU7PcX+gYHxmY/tDTz7eOzE8wCmPt1kcPo/LAJTFicQRngX4l2ERS4Uz4WzzsXr9wADBoSm/DyYc06ENbDfIzzs5ESkYzIlBPgpH0qAUpZ35pRyjFPRF7BkG4uEEyI2j0TFHcbET6CPiAFkk99fk8LOE7aMdfPertzY5XLQ0hJTjy2DJeinf4q6pqcqL8qtW1fm1fKiWvG1BrIMKXp8gGHfa9TbxSvt8mJRq8KgJw5oFH3DhAh1V61bstwpFUy51CkWHFUhPUuKhHPPCvW2qyrLu9p2X35aBiB0eHNs6rTf3bSai1plpTXEygxM/QvJKWzxfuKyLwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2 Types of Harf",
          "title": "2 Types of Harf",
          "src": "/static/38fd8bd07bd3200b14a6ef46e5ebc7ef/9144d/2-harf-2.png",
          "srcSet": ["/static/38fd8bd07bd3200b14a6ef46e5ebc7ef/222b7/2-harf-2.png 163w", "/static/38fd8bd07bd3200b14a6ef46e5ebc7ef/ff46a/2-harf-2.png 325w", "/static/38fd8bd07bd3200b14a6ef46e5ebc7ef/9144d/2-harf-2.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`مُخْتَصّ`}</strong>{` (Mukhtas)`}</p>
    <ul>
      <li parentName="ul">{`Harf which comes either with Ism or Fa'il,`}
        <ul parentName="li">
          <li parentName="ul">{`Ism(s) e.g. في in `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`في البيت`}</strong>{` `}</li>
          <li parentName="ul">{`Fa'il e.g. لم in `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`لم أذهب`}</strong></li>
        </ul>
      </li>
      <li parentName="ul">{`Harf مختص usually (`}<strong parentName="li">{`NOT ALWAYS`}</strong>{`) affects the word after it e.g. في made البيت in Jar etc.`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`غير مُخْتَصّ`}</strong>{` (Gairu Mukhtas)`}</p>
    <ul>
      <li parentName="ul">{`Harf which comes with both Ism or Fa'il e.g.`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`هل`}</strong>{` محمد هنا؟ (Usage with Ism)`}</li>
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`هل`}</strong>{` جاء محمد؟`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`الحمد لله people I feel I've covered enough details for beginners. And would like to conclude my article on `}<strong parentName="p">{`Parts of Speech in Arabic Grammar.`}</strong></p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Arabic",
          "rel": "nofollow noopener"
        }}>{`Arabic Wiki`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      